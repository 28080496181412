<template>
  <div class="leading-loose">
    <div class="flex mb-1">
      <spec-label class="w-1/6" label="Hatching System">
        <hatching-system-label :hatching-system="hatchingSystem" />
      </spec-label>
      <spec-label class="w-1/6" label="Cart">
        <cart-label v-if="cart" :cart="cart" />
      </spec-label>
      <spec-label
        class="w-1/6"
        is-date
        label="Started at"
        :value="process.started_at"
      />
      <spec-label
        class="w-1/6"
        is-date
        label="Completed at"
        :value="process.completed_at"
      />
      <spec-label class="w-1/6" label="Total process time">
        <span v-if="process.process_time">{{ process.process_time }}</span>
        <elapsed-time v-else :start-time="process.started_at" />
      </spec-label>
      <spec-label
        class="w-1/6"
        label="Cart position"
        :watch="`${process.cart_position}`"
        :value="process.cart_position"
      />
    </div>
    <div class="flex mb-1">
      <spec-label class="w-1/6" label="Egg batch" :value="eggBatch.id" />
      <spec-label class="w-1/6" label="Estimated Egg Count">
        {{ process.egg_batch.estimated_count }}
      </spec-label>
      <spec-label
        class="w-1/6"
        label="Total Larvae Count"
        :watch="process.egg_batch.larvae_count"
        :value="process.egg_batch.larvae_count"
      />
      <spec-label
        class="w-1/6"
        label="Hatching Rate"
        :watch="process.egg_batch.hatching_rate"
      >
        {{ process.egg_batch.hatching_rate }}%
      </spec-label>
      <spec-label class="w-2/6" v-if="showCartStatus" label="Cart Status">
        <cart-status v-if="cart" :cart="cart" class="text-sm" />
      </spec-label>
    </div>
    <div class="flex mb-1">
      <spec-label
        class="w-1/6"
        label="Larvae count"
        :watch="process.larvae_count"
        :value="process.larvae_count"
      />
      <spec-label
        class="w-1/6"
        is-date
        label="First hatch"
        :watch="process.first_larvae_hatched_at"
        :value="process.first_larvae_hatched_at"
      />
      <spec-label
        class="w-1/6"
        is-date
        label="Last hatch"
        :watch="process.last_larvae_hatched_at"
        :value="process.last_larvae_hatched_at"
      />
      <spec-label class="w-1/6" label="Total hatching time">
        <span v-if="process.hatching_time">
          {{ process.hatching_time }}
        </span>
        <span v-else>
          -
        </span>
      </spec-label>
    </div>
  </div>
</template>

<script>
import CartLabel from '@/components/carts/CartLabel';
import CartStatus from '@/components/carts/CartStatus';
import HatchingSystemLabel from '@/components/hatching-systems/HatchingSystemLabel';
import SpecLabel from '@/components/global/SpecLabel';
import ElapsedTime from '@/components/global/ElapsedTime';

export default {
  props: {
    process: {
      type: Object,
      required: true
    },
    showCartStatus: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SpecLabel,
    HatchingSystemLabel,
    CartStatus,
    CartLabel,
    ElapsedTime
  },
  computed: {
    hatchingSystem() {
      return this.process.hatching_system;
    },
    eggBatch() {
      return this.process.egg_batch;
    },
    cart() {
      return this.hatchingSystem.cart;
    }
  }
};
</script>
