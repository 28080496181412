<template>
  <fetch-json
    url="/hatching-processes"
    :filter="filter"
    disable-loader
    class="text-sm"
  >
    <template v-slot:header>
      <h2 class="font-bold text-xl">Completed Processes</h2>
    </template>
    <template v-slot:controls>
      <div class="flex mr-4">
        <select-field
          v-model="filter.hatching_system"
          label="Hatching System"
          url="/hatching-systems"
          :reduce="option => option.id"
          option-label="id"
          :move-label="false"
          class="w-48 shadow-md mr-4"
        />
        <select-field
          v-model="filter.egg_batch"
          label="Egg Batch"
          url="/egg-batches"
          :reduce="option => option.id"
          option-label="id"
          :move-label="false"
          class="w-48 shadow-md mr-4"
        />
        <select-field
          v-model="filter.cart"
          label="Cart"
          url="/carts"
          :reduce="option => option.id"
          option-label="id"
          :move-label="false"
          class="w-48 shadow-md mr-4"
        />
        <input-field
          v-model="filter.start_date"
          type="date"
          label="Start date"
          :move-label="false"
          class="w-48 shadow-md"
        />
      </div>
    </template>
    <template slot-scope="{ response: hatchingProcesses }">
      <hatching-process-item
        v-for="hatchingProcess in hatchingProcesses"
        :key="hatchingProcess.id"
        :process="hatchingProcess"
        :show-details="false"
        :show-cart-status="false"
        class="bg-white shadow rounded-lg overflow-hidden mb-6"
        @view-images="({ activity }) => onViewImages(activity)"
      />

      <view-larvae-images
        :show="viewImages"
        :activity="selectedHatchingActivity"
        @close="onClose"
      />
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import HatchingProcessItem from '@/components/hatching-process/HatchingProcessItem';
import ViewLarvaeImages from '@/components/hatching/process/ViewLarvaeImages';

export default {
  components: {
    ViewLarvaeImages,
    HatchingProcessItem,
    FetchJson
  },
  data() {
    return {
      viewImages: false,
      selectedHatchingActivity: null,
      filter: {
        status: 'completed',
        hatching_system: null,
        egg_batch: null,
        cart: null,
        start_date: null
      }
    };
  },
  methods: {
    onViewImages(activity) {
      this.viewImages = true;
      this.selectedHatchingActivity = activity;
    },
    onClose() {
      this.viewImages = false;
      this.selectedHatchingActivity = null;
    }
  }
};
</script>
