<template>
  <fetch-json url="/feeding-boxes" :filter="filter">
    <template v-slot:header>
      <div class="flex justify-between mb-4">
        <h1 class="text-2xl font-bold">Feeding boxes</h1>
        <primary-button @click="showFeedingBoxDialog = true">
          + Add New
        </primary-button>
      </div>
    </template>
    <template v-slot:controls>
      <div class="flex items-center">
        <input-field
          v-model="filter.rfid"
          label="Search RFID"
          :move-label="false"
          class="w-48 mr-4 shadow-md bg-white rounded"
        />
        <select-field
          v-model="filter.status"
          label="Status"
          :reduce="option => option.value"
          :fixed-options="statuses"
          :move-label="false"
          class="w-48 mr-4 shadow-md bg-white rounded"
        />
        <select-field
          v-model="filter.dimension"
          label="Dimension"
          url="/feeding-box-dimensions"
          :reduce="option => option.id"
          :move-label="false"
          class="w-48 mr-4 shadow-md bg-white rounded"
        />
      </div>
    </template>
    <template slot-scope="{ response: feedingBoxes, reload, deleteResource }">
      <table class="table-auto bg-white w-full rounded-lg shadow-md">
        <thead>
          <tr>
            <th class="text-left border px-4 py-2">Id</th>
            <th class="text-right border px-4 py-2">RFID</th>
            <th class="text-left border px-4 py-2">Dimensions (cm x cm)</th>
            <th class="text-right border px-4 py-2">Status</th>
            <th class="text-right border px-4 py-2">In use since</th>
            <th class="text-right border px-4 py-2">Total larvae count</th>
            <th class="text-right border px-4 py-2">Created At</th>
            <th class="text-right border px-4 py-2">Updated At</th>
            <th class="text-right border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <feeding-box-list-item
            v-for="feedingBox in feedingBoxes"
            :key="feedingBox.id"
            :feeding-box="feedingBox"
            class="mb-4"
            @edit="onEdit(feedingBox)"
            @delete="onDelete(feedingBox)"
            @change-status="onChangeStatus(feedingBox)"
          />
        </tbody>
      </table>

      <feeding-box-form
        :show="showFeedingBoxDialog"
        @close="onClose"
        @create="reload"
        @edit="reload"
        :feeding-box="selectedFeedingBox"
      />

      <change-status-dialog
        :show="showChangeStatusDialog"
        :feeding-box="selectedFeedingBox"
        @close="onClose"
        @update="onStatusUpdate(reload)"
      />

      <confirm-action-dialog
        :show="showDeleteDialog"
        type="delete"
        :resource="selectedFeedingBox"
        :action="deleteResource"
        @close="onClose"
        @done="reload"
      >
        <template slot="title">Mark feeding box as inactive?</template>
        <p>Are you sure you want to mark selected feeding box as inactive?</p>
      </confirm-action-dialog>
    </template>
  </fetch-json>
</template>

<script>
import qs from 'qs';
import FetchJson from '@/components/global/FetchJson';
import FeedingBoxListItem from '@/components/feeding-boxes/FeedingBoxListItem';
import FeedingBoxForm from '@/components/feeding-boxes/FeedingBoxForm';
import ChangeStatusDialog from '@/components/feeding-boxes/ChangeStatusDialog';

export default {
  name: 'FeedingBoxes',
  components: {
    ChangeStatusDialog,
    FeedingBoxForm,
    FeedingBoxListItem,
    FetchJson
  },
  data() {
    return {
      showFeedingBoxDialog: false,
      showDeleteDialog: false,
      showChangeStatusDialog: false,
      selectedFeedingBox: null,
      statuses: [
        { name: 'All status', value: null },
        { name: 'In Use', value: 'in-use' },
        { name: 'Empty', value: 'empty' },
        { name: 'Inactive', value: 'inactive' }
      ],
      filter: {
        status: null,
        rfid: null,
        dimension: null
      }
    };
  },
  created() {
    if (this.$route.query.filter) {
      this.filter = qs.parse(this.$route.query.filter);
    }
  },
  methods: {
    onClose() {
      this.showFeedingBoxDialog = false;
      this.showDeleteDialog = false;
      this.showChangeStatusDialog = false;
      this.selectedFeedingBox = null;
    },
    onEdit(feedingBox) {
      this.selectedFeedingBox = feedingBox;
      this.showFeedingBoxDialog = true;
    },
    onDelete(feedingBox) {
      this.selectedFeedingBox = feedingBox;
      this.showDeleteDialog = true;
    },
    onChangeStatus(feedingBox) {
      this.selectedFeedingBox = feedingBox;
      this.showChangeStatusDialog = true;
    },
    onStatusUpdate(reload) {
      this.onClose();
      reload();
    }
  }
};
</script>
