<template>
  <fetch-json url="/hatching-processes" :filter="filter" class="text-sm">
    <template v-slot:header>
      <div class="flex justify-between mb-4">
        <h2 class="font-bold text-xl">Active Processes</h2>
        <primary-button @click="hatchingProcessFormIsVisible = true">
          Start New Process
        </primary-button>
      </div>
    </template>
    <template v-slot:controls>
      <div class="flex mr-4">
        <select-field
          v-model="filter.hatching_system"
          label="Hatching System"
          url="/hatching-systems"
          :reduce="option => option.id"
          option-label="id"
          :move-label="false"
          class="w-48 shadow-md mr-4"
        />
        <select-field
          v-model="filter.egg_batch"
          label="Egg Batch"
          url="/egg-batches"
          :reduce="option => option.id"
          option-label="id"
          :move-label="false"
          class="w-48 shadow-md mr-4"
        />
        <select-field
          v-model="filter.cart"
          label="Cart"
          url="/carts"
          :reduce="option => option.id"
          option-label="id"
          :move-label="false"
          class="w-48 shadow-md mr-4"
        />
        <input-field
          v-model="filter.start_date"
          type="date"
          label="Start date"
          :move-label="false"
          class="w-48 shadow-md"
        />
      </div>
    </template>
    <template slot-scope="{ response: hatchingProcesses, reload }">
      <hatching-process-item
        v-for="hatchingProcess in hatchingProcesses"
        :key="hatchingProcess.id"
        :process="hatchingProcess"
        class="bg-white shadow rounded-lg overflow-hidden mb-6"
        @open-live-stream="openLiveStream(hatchingProcess)"
        @action="triggerEvent(hatchingProcess, $event)"
      />
      <start-hatching-process
        :show="hatchingProcessFormIsVisible"
        @start="reload"
        @close="hatchingProcessFormIsVisible = false"
      />

      <live-stream-dialog
        v-if="selectedHatchingProcess"
        :show="liveStreamIsVisible"
        :hatching-process="selectedHatchingProcess"
        @close="onClose"
      />
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import HatchingProcessItem from '@/components/hatching-process/HatchingProcessItem';
import StartHatchingProcess from '@/components/hatching-process/StartHatchingProcess';
import { handleFailedResponse } from '@/core/helpers';
import LiveStreamDialog from './LiveStreamDialog';

export default {
  components: {
    LiveStreamDialog,
    StartHatchingProcess,
    HatchingProcessItem,
    FetchJson
  },
  data() {
    return {
      hatchingProcessFormIsVisible: false,
      liveStreamIsVisible: false,
      viewImages: false,
      selectedHatchingProcess: null,
      filter: {
        status: 'ongoing',
        hatching_system: null,
        egg_batch: null,
        cart: null,
        start_date: null
      }
    };
  },
  methods: {
    triggerEvent(hatchingProcess, event) {
      this.axios
        .post(`/hatching-processes/${hatchingProcess.id}/${event}`)
        .catch(handleFailedResponse);
    },
    onClose() {
      this.viewImages = false;
      this.selectedHatchingProcess = null;
      this.liveStreamIsVisible = false;
    },
    openLiveStream(hatchingProcess) {
      this.selectedHatchingProcess = hatchingProcess;
      this.liveStreamIsVisible = true;
    }
  }
};
</script>
