<template>
  <transition name="fade" mode="out-in">
    <span :key="status">{{ status || 'Unknown' }}</span>
  </transition>
</template>

<script>
export default {
  props: {
    cart: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      status: null
    };
  },
  mounted() {
    this.$echo
      .channel(`dashboard.cart.${this.cart.id}`)
      .listen('.cart.status', message => {
        this.status = message.status;
      });
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
