<template>
  <div v-if="processing" class="text-center">
    <div class="text-gray-600 border rounded py-1 mb-2">
      <fa-icon
        :icon="['fas', 'spinner-third']"
        class="fa-fw fa-spin text-blue-500 mr-2"
      />
      Please wait. Checking cart if is ready.
    </div>
    <div v-if="recheck">
      <p class="text-red-500 text-sm mb-1">
        Cart did not respond in time. Check cart is online and then retry.
      </p>
      <button @click.stop="check" class="text-sm border px-2 py-1">
        <fa-icon :icon="['fas', 'redo']" class="fa-fw text-gray-700 mr-2" />
        Retry
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hatchingSystem: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      recheck: false
    };
  },
  mounted() {
    this.track();
  },
  methods: {
    track() {
      if (!this.hatchingSystem) {
        return;
      }

      this.processing = true;
      this.$echo
        .channel(`dashboard.hatching_system.${this.hatchingSystem}`)
        .listen('.cart.connected', () => {
          this.$emit('connection', true);
          this.processing = false;
        });

      this.check();
    },
    check() {
      this.axios.post(
        `/hatching-systems/${this.hatchingSystem}/check-cart-connection`
      );
      this.recheck = false;

      const intervalId = setInterval(() => {
        clearInterval(intervalId);
        this.recheck = true;
      }, 10000);
    }
  },
  beforeDestroy() {
    this.$echo.leave(`dashboard.hatching_system.${this.hatchingSystem}`);
  }
};
</script>
