<template>
  <div>
    <hatching-processes />
  </div>
</template>

<script>
import HatchingProcesses from '@/components/hatching-process/HatchingProcesses';

export default {
  components: { HatchingProcesses }
};
</script>
