<template>
  <modal-dialog :show="show" @close="onClose" fullWidth>
    <div class="text-center mb-4">
      <h3 class="font-bold mb-2">Live Stream</h3>

      <p>{{ message }}</p>
    </div>
    <div class="flex flex-col items-center relative mb-4">
      <video id="video" autoplay playsinline height="540" width="960"></video>

      <div
        v-if="is_processing"
        class="absolute top-0 left-0 w-full h-full bg-white flex justify-center items-center"
      >
        <fa-icon
          :icon="['fas', 'spinner-third']"
          class="fa-fw fa-spin text-3xl"
        />
      </div>
    </div>
    <div class="text-center">
      <div
        v-if="connection_failed && !is_processing"
        class="border px-4 py-1 rounded mb-2"
      >
        <button @click="startStream">Retry</button>
      </div>

      <button @click="onClose" class="border px-4 py-1 rounded">close</button>
    </div>
  </modal-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    hatchingProcess: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      peer: null,
      src: null,
      uuid: null,
      is_live_stream_started: false,
      is_processing: false,
      connection_failed: false,
      message: null
    };
  },
  mounted() {
    this.connect();
  },
  methods: {
    onClose() {
      this.closeStream();
      if (this.peer) {
        this.peer.close();
      }
      this.$emit('close');
    },
    async checkStreamStatus() {
      this.is_processing = true;
      await this.axios.post(
        `/hatching-processes/${this.hatchingProcess.id}/check-stream`
      );
    },
    async startStream() {
      this.is_processing = true;
      this.uuid = uuidv4();
      this.message = 'Preparing Pi for live stream.';
      await this.axios.post(
        `/hatching-processes/${this.hatchingProcess.id}/start-stream`,
        {
          uuid: this.uuid
        }
      );
    },
    async closeStream() {
      this.is_processing = true;
      await this.axios.post(
        `/hatching-processes/${this.hatchingProcess.id}/stop-stream`,
        {
          uuid: this.uuid
        }
      );
    },
    connect() {
      this.$echo
        .channel(`dashboard.hatching-process.${this.hatchingProcess.id}`)
        .listen('.live-stream.status', ({ uuid }) => {
          if (uuid === this.uuid) {
            this.establishConnection(uuid);
          }

          this.is_processing = false;
        });

      this.startStream();
    },
    async establishConnection(uuid) {
      this.message = 'Preparing live stream.';
      const iceConfiguration = {};
      iceConfiguration.iceServers = [
        {
          urls: process.env.VUE_APP_TURN_URL,
          username: process.env.VUE_APP_TURN_USER,
          credential: process.env.VUE_APP_TURN_PASSWORD
        },
        {
          urls: process.env.VUE_APP_STUN_URL
        },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' }
      ];

      this.peer = new RTCPeerConnection(iceConfiguration);

      this.peer.onicecandidate = () => {
        console.log(' NEW ice candidate!! on peer reprinting SDP ');
        console.log(JSON.stringify(this.peer.localDescription));
      };

      this.peer.onconnectionstatechange = e => {
        console.log('signalingState: ', e.currentTarget.signalingState);
        console.log('connectionState: ', e.currentTarget.connectionState);
        console.log('iceConnectionState: ', e.currentTarget.iceConnectionState);
        console.log('iceGatheringState: ', e.currentTarget.iceGatheringState);
      };

      this.peer.ontrack = e => {
        document.getElementById('video').srcObject = e.streams[0];
      };

      const { data: offer } = await this.axios.get(
        `/hatching-processes/${this.hatchingProcess.id}/offer?uuid=${uuid}`
      );

      if (!offer) {
        this.message = 'No valid offer available. Check if Pi is working.';
        this.connection_failed = true;
        this.is_processing = false;

        return;
      }

      await this.peer.setRemoteDescription(new RTCSessionDescription(offer));
      await this.peer.setLocalDescription(await this.peer.createAnswer());

      this.message = 'Establishing connection with Pi. Please wait...';
      setTimeout(() => {
        const answer = JSON.stringify({
          type: this.peer.localDescription.type,
          sdp: this.peer.localDescription.sdp
        });

        this.axios.post(
          `/hatching-processes/${this.hatchingProcess.id}/answer`,
          { answer, uuid }
        );
        this.message = 'Attempting to start the stream.';
      }, 3000);
    }
  }
};
</script>
