<template>
  <span v-if="cart.name">
    #{{ cart.id }}
    <span>({{ cart.name }})</span>
  </span>
</template>

<script>
export default {
  props: {
    cart: {
      type: Object,
      required: true
    }
  }
};
</script>
