<template>
  <expansion-panel :open-by-default="showDetails">
    <template slot-scope="{ toggle, expanded }">
      <div class="flex justify-between p-4">
        <div class="font-bold">
          <span class="mr-4">Process #{{ hatchingProcess.id }}</span>
          <span v-if="hatchingProcess" class="text-gray-800 font-normal">
            {{ hatchingProcessStatus }}
          </span>
        </div>
        <div
          v-if="completed"
          class="text-green-600 text-sm font-bold border border-green-300 px-2 rounded"
        >
          <fa-icon
            :icon="['fas', 'check-circle']"
            class="text-green-500 mr-1"
          />
          process completed
        </div>
        <div v-else class="text-sm">
          <button
            class="bg-blue-100 font-bold text-blue-700 px-2 py-1 rounded text-xs transition-all duration-300 hover:bg-blue-300 mr-4"
            @click.stop="$emit('open-live-stream')"
          >
            <fa-icon :icon="['fas', 'signal-stream']" />
          </button>
          <button
            class="bg-red-200 font-bold text-red-700 px-2 py-1 rounded text-xs transition-all duration-300 hover:bg-red-300 mr-4"
            @click.stop="$emit('action', 'terminating')"
          >
            Terminate
          </button>
          <button
            class="bg-gray-200 font-bold text-gray-800 px-2 py-1 rounded text-xs transition-all duration-300 hover:bg-gray-400 mr-4"
            @click.stop="$emit('action', 'resuming')"
          >
            Resume
          </button>
          <button
            class="bg-green-200 font-bold text-green-800 px-2 py-1 rounded text-xs transition-all duration-300 hover:bg-green-300"
            @click.stop="$emit('action', 'completing')"
          >
            Complete
          </button>
        </div>
      </div>

      <hatching-details
        :process="hatchingProcess"
        :show-cart-status="showCartStatus"
        class="px-4 pb-4"
      />

      <button
        @click.stop="toggle()"
        class="w-full text-xs py-1 text-gray-700 bg-gray-100 hover:bg-gray-300 transition-colors duration-300"
      >
        {{ expanded ? 'Hide' : 'Show' }} process details
      </button>
    </template>
    <template slot="content">
      <div class="p-4">
        <h3 class="font-bold mb-2">Activities</h3>
        <p
          v-if="hatchingProcess.hatching_activities.length === 0"
          class="text-gray-700"
        >
          No hatching activity performed.
        </p>

        <div v-else class="border rounded">
          <table class="table-auto w-full leading-loose">
            <thead>
              <tr class="text-xs border-b">
                <th class="border-r px-2 py-1 text-left">Id</th>
                <th class="border-r px-2 py-1 text-left">RFID</th>
                <th class="border-r px-2 py-1 text-left">
                  Total hatching time
                </th>
                <th class="border-r px-2 py-1 text-left">
                  Set number of larvae
                </th>
                <th class="border-r px-2 py-1 text-left">Larvae count</th>
                <th class="border-r px-2 py-1 text-left">First hatch</th>
                <th class="border-r px-2 py-1 text-left">Last hatch</th>
                <th class="border-r px-2 py-1 text-left">Status</th>
                <th class="border-r px-2 py-1 text-left">Started at</th>
                <th class="border-r px-2 py-1 text-left">Completed at</th>
              </tr>
            </thead>
            <tbody>
              <hatching-activity-list-item
                v-for="feedingBox in hatchingProcess.hatching_activities"
                :key="feedingBox.id"
                :feeding-box="feedingBox"
                class="border-b last:border-b-0"
              />
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </expansion-panel>
</template>

<script>
import HatchingActivityListItem from '@/components/hatching/activity/HatchingActivityListItem';
import ExpansionPanel from '@/components/global/ui/ExpansionPanel';
import HatchingDetails from '@/components/hatching/process/HatchingDetails';

export default {
  components: {
    HatchingActivityListItem,
    ExpansionPanel,
    HatchingDetails
  },
  props: {
    process: {
      type: Object,
      required: true
    },
    showDetails: {
      type: Boolean,
      default: true
    },
    showCartStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hatchingProcess: this.process,
      hatchingProcessStatus: null,
      localConnection: null,
      sendChannel: null
    };
  },
  computed: {
    completed() {
      return this.hatchingProcess.completed_at;
    }
  },
  mounted() {
    if (!this.completed) {
      this.connect();
    }
  },
  methods: {
    connect() {
      this.$echo
        .channel(`dashboard.hatching-process.${this.hatchingProcess.id}`)
        .listen('.hatching-process.status', message => {
          this.hatchingProcessStatus = message.hatchingProcess.status;
        });

      this.$echo
        .channel(`hatching-process.${this.hatchingProcess.id}`)
        .listen('.updated', process => {
          this.hatchingProcess = process;
        });
    }
  }
};
</script>
