<script>
export default {
  props: {
    openByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: this.openByDefault
    };
  },
  render(h) {
    const content = h(
      'div',
      {
        class: {
          'expansion-panel transition-all duration-300': true,
          'panel-opened': this.expanded
        }
      },
      [this.$slots.content]
    );

    return h(
      'div',
      {
        class: {
          'cursor-pointer': true
        },
        on: {
          click: () => this.toggle()
        }
      },
      [
        this.$scopedSlots.default({
          toggle: this.toggle,
          expanded: this.expanded
        }),
        content
      ]
    );
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style>
.expansion-panel {
  overflow: hidden;
  max-height: 0;
}

.panel-opened {
  max-height: 3000px;
}
</style>
