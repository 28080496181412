<template>
  <modal-dialog v-if="feedingBox" :show="show" width="500">
    <h1 class="font-bold text-xl mb-4">Change feeding box status</h1>
    <p class="mb-4">Id: {{ feedingBox.id }}</p>
    <p class="mb-4">RFID: {{ feedingBox.rfid }}</p>
    <p class="mb-4">Dimension: {{ feedingBox.dimension.name }}</p>
    <p class="mb-4">Current status: {{ feedingBox.status }}</p>

    <div class="text-center">
      <primary-button @click="update" :loading="processing" class="w-full mb-4">
        Change status to empty
      </primary-button>
      <button @click="$emit('close')">close</button>
    </div>
  </modal-dialog>
</template>

<script>
import { handleFailedResponse } from '@/core/helpers';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    feedingBox: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false
    };
  },

  methods: {
    async update() {
      this.processing = true;
      try {
        await this.axios.patch(`/feeding-boxes/${this.feedingBox.id}/status`, {
          status: 'empty'
        });

        this.$emit('update');
      } catch (e) {
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
