<template>
  <span>#{{ hatchingSystem.id }}</span>
</template>

<script>
export default {
  props: {
    hatchingSystem: {
      type: Object,
      required: true
    }
  }
};
</script>
