<template>
  <tr>
    <td class="border px-4 py-2">#{{ feedingBox.id }}</td>
    <td class="border px-4 py-2 text-right">{{ feedingBox.rfid }}</td>
    <td class="border px-4 py-2 text-left">
      {{ feedingBox.dimension.name }}
      <span class="text-xs text-gray-600">
        ({{ feedingBox.dimension.length }} x {{ feedingBox.dimension.width }})
      </span>
    </td>
    <td class="border px-4 py-2 text-right">{{ feedingBox.status }}</td>
    <td class="border px-4 py-2 text-right">
      <elapsed-time
        v-if="feedingBox.status === 'in-use'"
        :start-time="
          feedingBox.latest_hatching_activity.first_larvae_hatched_at
        "
      />
      <span v-else>-</span>
    </td>
    <td class="border px-4 py-2 text-right">
      <span v-if="feedingBox.status === 'in-use'">
        {{ feedingBox.latest_hatching_activity.larvae_count }}
      </span>
      <span v-else>-</span>
    </td>
    <td class="border px-4 py-2 text-right">{{ feedingBox.created_at }}</td>
    <td class="border px-4 py-2 text-right">{{ feedingBox.updated_at }}</td>
    <td class="border px-4 py-2 text-right text-sm">
      <action-button
        v-if="feedingBox.status === 'in-use'"
        icon="sync-alt"
        @click.native="$emit('change-status')"
        class="mr-2"
      />
      <action-button
        v-if="feedingBox.status === 'empty' && feedingBox.status !== 'inactive'"
        icon="trash"
        @click.native="$emit('delete')"
      />
      <action-button
        v-if="feedingBox.status !== 'inactive'"
        icon="edit"
        @click.native="$emit('edit')"
        class="mr-2"
      />
    </td>
  </tr>
</template>

<script>
import ActionButton from '@/components/global/form/ActionButton';
import ElapsedTime from '@/components/global/ElapsedTime';

export default {
  components: { ElapsedTime, ActionButton },
  props: {
    feedingBox: {
      type: Object,
      required: true
    }
  },
  computed: {
    hatchingSystem() {
      if (!this.feedingBox.hatching_system) {
        return;
      }

      return this.feedingBox.hatching_system.id;
    }
  }
};
</script>
