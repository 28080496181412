<template>
  <div>{{ time }}</div>
</template>

<script>
import { getElapsedTime } from '@/core/helpers';
import dayjs from '@/plugins/dayjs';

export default {
  props: {
    startTime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      time: '00:00:00',
      interval: null
    };
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      const startTime = this.getStartTime();

      this.interval = setInterval(() => {
        this.time = getElapsedTime(startTime);
      }, 1000);
    },
    getStartTime() {
      const currentTime = new Date();
      const startTime = dayjs.utc(this.startTime).toDate();

      currentTime.setHours(currentTime.getHours() - startTime.hours);
      currentTime.setMinutes(currentTime.getMinutes() - startTime.minutes);
      currentTime.setSeconds(currentTime.getSeconds() - startTime.seconds);

      return startTime;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  }
};
</script>
