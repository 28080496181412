<template>
  <modal-dialog :show="show" @close="close">
    <h1 class="text-center text-2xl font-bold mb-4">
      Start Hatching Process
    </h1>

    <select-field
      v-model="form.egg_batch"
      :reduce="option => option.id"
      :error="form.errors.get('egg_batch')"
      label="Select egg batch"
      option-label="id"
      :filter="{ available: true }"
      :get-option-label="getEggBatchOptionLabel"
      url="/egg-batches"
      class="mb-6"
    >
      <template v-slot:default="{ option }">
        <div class="p-2 border-b">
          <div class="flex mb-2">
            <spec-label
              label="Egg batch id"
              :value="option.id"
              class="w-1/2 mr-4"
            />
            <spec-label label="Status" :value="option.status" class="w-1/2" />
          </div>
          <div class="flex">
            <spec-label
              label="Estimated Count"
              :value="option.estimated_count"
              class="w-1/2 mr-4"
            />
            <spec-label
              label="Larvae Count"
              class="w-1/2"
              :value="`${option.larvae_count} (${option.hatching_rate}%)`"
            />
          </div>
        </div>
      </template>
    </select-field>

    <select-field
      v-model="form.hatching_system"
      :reduce="option => option.id"
      :error="form.errors.get('hatching_system')"
      label="Select hatching system"
      option-label="id"
      :filter="{ status: 'available' }"
      url="/hatching-systems"
      @change="connected = false"
      class="mb-6"
    />

    <check-cart-is-ready
      v-if="!connected"
      :hatching-system="form.hatching_system"
      :key="form.hatching_system"
      @connection="connected => (this.connected = connected)"
      class="mb-4"
    />
    <p v-else class="text-center mb-4">
      <fa-icon :icon="['fas', 'check-circle']" class="fa-fw text-green-500" />
      Cart is connected.
    </p>

    <div class="text-center">
      <primary-button
        :loading="processing"
        :disabled="!connected"
        class="w-full mb-4"
        @click="start"
      >
        Start
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
import SpecLabel from '@/components/global/SpecLabel';
import CheckCartIsReady from '@/components/CheckCartIsReady';

export default {
  components: { SpecLabel, CheckCartIsReady },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      connected: false,
      form: this.$form({
        hatching_system: null,
        egg_batch: null
      })
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async start() {
      this.processing = true;
      try {
        const { data } = await this.form.submit(
          'post',
          '/hatching-process/start'
        );

        this.$emit('start', { process: data });
        this.form.reset();
        this.connected = false;
        this.close();
      } finally {
        this.processing = false;
      }
    },
    getEggBatchOptionLabel(option) {
      return `${option.id} - (${option.status})`;
    }
  }
};
</script>
