<template>
  <tr>
    <td class="border-r px-2 py-1">
      {{ activity.id }}
    </td>
    <td class="border-r px-2 py-1">
      {{ feedingBox.rfid }}
    </td>
    <td class="border-r px-2 py-1">
      {{ activity.hatching_time || '-' }}
    </td>
    <td class="border-r px-2 py-1">
      {{ feedingBox.dimension.no_of_larvae }}
    </td>
    <td class="border-r px-2 py-1">
      {{ activity.larvae_count }}
    </td>
    <td class="border-r px-2 py-1">
      <local-date :date="activity.first_larvae_hatched_at" />
    </td>
    <td class="border-r px-2 py-1">
      <local-date :date="activity.last_larvae_hatched_at" />
    </td>
    <td class="border-r px-2 py-1">
      {{ feedingBox.status }}
    </td>
    <td class="border-r px-2 py-1">
      <local-date :date="activity.created_at" />
    </td>
    <td class="border-r px-2 py-1">
      <local-date :date="activity.completed_at" />
    </td>
  </tr>
</template>

<script>
import LocalDate from '@/components/global/LocalDate';

export default {
  components: { LocalDate },
  props: {
    feedingBox: {
      type: Object,
      required: true
    }
  },
  computed: {
    activity() {
      return this.feedingBox.pivot;
    }
  }
};
</script>
