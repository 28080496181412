<script>
import LocalDate from '@/components/global/LocalDate';

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    watch: {
      type: [String, Number],
      default: null
    },
    isDate: {
      type: Boolean,
      default: false
    }
  },
  render(h) {
    let content = this.$slots.default || '-';

    if (this.value || this.value === 0 || this.value === '0') {
      content = [
        this.isDate
          ? h(LocalDate, { props: { date: this.value } })
          : this.value,
        h('span', { class: 'text-xs text-gray-800' }),
        this.suffix
      ];
    }

    const data = {};

    if (this.watch) {
      Object.assign(data, {
        key: this.watch
      });
    }

    return h('div', [
      h('div', { class: 'text-xs text-gray-600' }, this.label),
      h('transition', { props: { name: 'fade', mode: 'out-in' } }, [
        h('div', data, content)
      ])
    ]);
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
