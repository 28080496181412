<template>
  <modal-dialog :show="show" @close="close">
    <h1 class="text-center text-2xl font-bold mb-4">
      Feeding Box
    </h1>
    <div class="flex mb-4">
      <input-field
        v-model="form.rfid"
        label="RFID"
        class="w-1/2 mr-4"
        :error="form.errors.get('rfid')"
      />
      <select-field
        v-model="form.dimension"
        :reduce="option => option.id"
        :error="form.errors.get('dimension')"
        label="Select dimension"
        url="/feeding-box-dimensions"
        class="w-1/2"
      />
    </div>
    <div class="text-center">
      <primary-button :loading="processing" class="w-full mb-4" @click="submit">
        Submit
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    feedingBox: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      form: this.$form({
        rfid: null,
        dimension: null
      })
    };
  },
  watch: {
    feedingBox(value) {
      if (value === null) {
        this.form.reset();
        return;
      }

      this.form.rfid = value.rfid;
      this.form.dimension = value.dimension_id;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.feedingBox ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        await this.form.submit('post', '/feeding-boxes');

        this.$emit('create');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit('patch', `/feeding-boxes/${this.feedingBox.id}`);

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
