<template>
  <modal-dialog :show="show" width="800">
    <div class="flex justify-between mb-2">
      <h1 class="font-bold text-xl">
        Larvae Images (RFID: {{ activity && activity.rfid }})
      </h1>
      <button @click="$emit('close')" class="rounded-full p-2 text-xs">
        <fa-icon :icon="['fas', 'times']" />
      </button>
    </div>
    <div class="text-right">
      <button
        v-if="meta && meta.current_page !== 1"
        @click="loadImages(meta.current_page - 1)"
        class="bg-gray-200 rounded px-4 py-1 hover:bg-gray-400 transition-all duration-300 mr-4"
      >
        <fa-icon :icon="['fas', 'arrow-left']" />
        Prev
      </button>
      <button
        v-if="meta && meta.current_page < meta.last_page"
        @click="loadImages(meta.current_page + 1)"
        class="bg-gray-200 rounded px-4 py-1 hover:bg-gray-400 transition-all duration-300 mr-4"
      >
        Next
        <fa-icon :icon="['fas', 'arrow-right']" />
      </button>
      <button
        @click="loadImages"
        class="bg-gray-200 rounded px-4 py-1 hover:bg-gray-400 transition-all duration-300"
      >
        Refresh
      </button>
    </div>
    <div v-if="processing" class="flex flex-col items-center">
      <fa-icon
        :icon="['fas', 'spinner-third']"
        class="fa-spin text-2xl text-blue-500 mb-2"
      />
      loading
    </div>
    <div v-else>
      <div v-for="image in images" :key="image.id" class="border-b py-4">
        <div class="flex mb-4">
          <img :src="image.path" class="w-1/2 mr-2" />
          <img :src="image.output" class="w-1/2" />
        </div>
        <div class="flex">
          <div class="w-1/2">
            <div>Id: {{ image.id }}</div>
            <div>Count: {{ image.count }}</div>
          </div>
          <div class="w-1/2 text-right">
            <div>Captured at: <local-date :date="image.created_at" /></div>
            <div>Counted at: <local-date :date="image.counted_at" /></div>
          </div>
        </div>
      </div>
      <p v-if="images.length === 0">No images captured yet.</p>
    </div>
  </modal-dialog>
</template>

<script>
import { handleFailedResponse } from '@/core/helpers';
import LocalDate from '@/components/global/LocalDate';

export default {
  components: { LocalDate },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    activity: {
      validator: value => {
        return (
          typeof value === 'object' || value === null || value === undefined
        );
      },
      required: true
    }
  },
  data() {
    return {
      processing: false,
      images: [],
      meta: null
    };
  },
  watch: {
    activity(value) {
      if (value) {
        this.loadImages();
      }
    }
  },
  methods: {
    async loadImages(page = 1) {
      this.processing = true;

      try {
        const { data } = await this.axios.get(
          `/hatching-activities/${this.activity.id}/images?page=${page}`
        );
        this.images = data.data;
        this.meta = data.meta;
      } catch (e) {
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
